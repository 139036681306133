.social-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 70px;
    .item {
        margin: 15px;
        text-align: center;
        @media (max-width: 768px) {
            flex: 0 0 calc(50% - 30px);
        }
    }
    a {
        display: inline-block;
        img {
            transition: filter linear 250ms;
            &:hover {
                filter: drop-shadow(0px 0px 7px #0FBCFC);
                -webkit-filter: drop-shadow(0px 0px 7px #0FBCFC);
            }
        }
        
    }
}
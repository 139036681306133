.fungible-token {
    .cyborgs-list {
        @media (max-width: 768px) {
            max-width: 400px;
            margin: 0px auto;
        }
    }
    .cyborgs-list,
    .items-list {
        background-image: url(../img/character-frame.png);
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
        margin-top: 20px;
        &:before {
            content: '';
            display: block;
            padding-top: 141%;
        }
        > .cyborgs-list-body,
        > .items-list-body {
            top: 18.5%;
            left: 9.2%;
            position: absolute;
            width: 82%;
            > .cyborgs-list-item,
            > .items-list-item {
                flex-wrap: wrap;
                justify-content: space-between;
                display: none;
                position: relative;
                &.active {
                    display: flex;
                }
                > * {
                    flex: 0 0 48%;
                    margin-bottom: 3%;
                    img {
                        width: 100%;
                        transition: filter linear 250ms;
                        cursor: pointer;
                    }
                    &.active {
                        img {
                            filter: drop-shadow(0px 0px 7px #0FBCFC);
                            -webkit-filter: drop-shadow(0px 0px 7px #0FBCFC);
                        }
                    }
                }
                
            }
        }
        .class-list {
            top: 87.6%;
            left: 7%;
            position: absolute;
            width: 82%;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            padding-bottom: 2%;
            .item {
                line-height: 0;
                padding: 1.2% 0;
                flex: 0 0 16.666666%;
                text-align: center;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                      z-index:  #{5 - $i};
                    }
                  }
               &:first-child {
                &:after {
                    content: '';
                    width: 80%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #272C57;
                }
               }
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #272C57;
                    transform: skewX(-30deg);
                    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
                    z-index: 1;
                }
                img {
                    position: relative;
                    z-index: 1;
                    line-height: 0;
                    opacity: 0.5;
                    width: 42.9%;
                }
                &.active {
                    &:before {
                        background: #1c235e;
                    }
                    img {
                        opacity: 1;
                    }
                    &:first-child {
                        &:after {
                            background: #1c235e;
                        }
                    }
                }
            }
        }
    }
    .cyborgs-character-list,
    .items-character-list {
        margin-top: 55px;
        height: calc(100% - 55px);
        flex-wrap: wrap;
        position: relative;
        .board-information  {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        .cyborgs-character-item-wrapper {
            @media (max-width: 768px) {
                margin: 100px 0 150px;
            }
        }
        .cyborgs-character-item-wrapper,
        .items-character-item-wrapper {
            position: relative;
            max-width: 80%;
            .circle-1 {
                position: absolute;
                top: -30%;
                left: 50%;
                width: 120%;
                transform: translate(-50%, 0);
                animation: rotation 10s infinite linear;
            }
            .circle-2 {
                position: absolute;
                bottom: -20%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, 0);
                text-align: center;
            }
            @keyframes rotation {
                from {
                  transform: translate(-50%, 0) rotate(0deg);
                }
                to {
                  transform: translate(-50%, 0)rotate(359deg);
                }
              }
        }
        .cyborgs-character-list-item,
        .items-character-list-item {
            display: none;
            text-align: center;
            position: relative;
            z-index: 1;
            > img {
                animation: pullInUp 2s infinite ease-in-out;
            }
            @keyframes pullInUp {
                0% {
                  transform: translate(0%, 0);
                }
                50% {
                  transform: translate(0%, 20px);
                }
                100% {
                    transform: translate(0%, 0);
                  }
              }
            &.active {
                display: block;
            }
            .cybor-information,
            .items-information {
                width: 100%;
                background-image: url(../img/information-board.png);
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                display: none;
                &:after {
                    content: '';
                    padding-top: 130%;
                    display: block;
                    width: 100%;
                }
                .inside {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 274px;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    .infor-list {
                        max-width: 235px;
                        margin: 0 auto;
                        .item {
                            display: flex;
                            flex-wrap: wrap;
                            background-image: url(../img/information/board-type.png);
                            background-size: 100%;
                            background-position: top center;
                            background-repeat: no-repeat;
                            height: 47px;
                            align-items: center;
                            margin-bottom: 16px;
                            &:first-child {
                                .icon {
                                    img {
                                        transform: translate(-22px, -8px);
                                    }
                                }
                            }
                            .icon {
                                line-height: 0;
                                flex: 0 0 61px;
                                height: 47px;
                                text-align: left;
                            }
                            .type-name {
                                font-family: 'Front Page Neue';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 23px;
                                color: #FDEC2F;
                                padding-top: 5px;
                                &.green {
                                    color: #78D24D;

                                }
                                &.blue {
                                    color: #96A4FF;
                                }
                                &.gray {
                                    color: #8C92BC;
                                }
                                &.pink {
                                    color: #B64DF2;
                                }
                            }
                            .name {
                                text-align: right;
                                margin-left: auto;
                                padding:9px 13px 0 0;
                                font-family: 'Front Page Neue';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 23px;
                                /* identical to box height */


                                color: #FFFFFF;

                            }
                        }
                    }
                    .fuel {
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 274px;
                        margin: 0px auto;
                        .name,
                        .percent {
                            flex: 0 0 50%;
                            font-family: 'Front Page Neue';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 28px;
                            /* identical to box height */
                            text-align: left;

                            color: #FFFFFF;
                            margin-bottom: 4px;

                        }
                        .percent {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .cybor-name  {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        > * {
            flex: 0 0 100%;
        }
    }
} 
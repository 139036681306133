.section-panel {
    margin-top: 100px;
    .section-panel-wrapper {
        background: #3C437D;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -4px 1px rgba(221, 253, 255, 0.5);
    }
    &-heading {
        padding: 13px 35px 10px;
        box-shadow:  0px 4px 1px rgba(0, 31, 79, 0.5);
        background: #22285C;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        @media (max-width: 768px) {
            padding: 8px 9px;
        }
        > * {
            font-weight: 400;
            font-size: rem(32px);
            line-height: rem(37px);
            /* identical to box height */

            letter-spacing: 0.1em;

            background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin: 0;
            @media (max-width: 768px) {
                font-size: rem(24px);
                line-height: rem(28px);
            }
        }
    }
    &-body {
        padding: 25px 35px;

        @media (max-width: 768px) {
            padding: 24px 9px;
        }
        .betting-head {
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 768px) {
                flex-wrap: nowrap;
                padding: 0 7px;
            }
            a {
                display: inline-flex;
                align-items: center;
                padding: 0;
                font-family: 'Hi Jack';
                font-weight: 400;
                font-size: 28px;
                line-height: 32px;
                letter-spacing: 0.05em;
                color: rgba(#FFFFFF, 0.6);
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 20px;
                    flex: 33.333333%;
                    position: relative;
                }
                &.active {
                    color: #FFFFFF;
                }
                &:after {
                    content: '';
                    height: 33px;
                    width: 5px;
                    background-color: #FFFFFF;
                    margin:0 60px;
                    @media (max-width: 768px) {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: calc(50% + 40px);
                        width: 2px;
                        height: 20px;
                        transform: translate(-50%, -50%);
                    }
                }
                &:first-child {
                    padding: 0;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        
    }
}
.player-card {
    background-image: url(../img/betting-frame.png);
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    &:before {
        content: '';
        padding-top: 141%;
        display: block;
    }
    .player-name {
        position: absolute;
        top: 7.2%;
        left: 50%;
        transform: translate(-50%, 0);
        font-weight: 400;
        font-size: calc(0.9rem + 1vmin);
        line-height: 32px;

        color: #FFFFFF;

    }
    .player-body{
        width: 70%;
        position: absolute;
        top: 22%;
        left: 50%;
        transform: translate(-50%, 0);
        @media (max-width: 768px) {
           width: 90%;
        }
        .player-infor {
            margin-top: 5%;
            .infor {
                display: flex;
                align-items: center;
                border-bottom: 2px solid rgba(0, 0, 0, 0.15);
                font-weight: 400;
                font-size: calc(0.05rem + 1vmin);
                line-height: 23px;
                padding: 2% 5%;
                /* identical to box height */

                
                color: #FFFFFF;

                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                @media (max-width: 768px) {
                    font-size: 14px;
                }
                &:last-child {
                    border: none;
                }
                .name {
                    margin-left: auto;
                }
            }
        }
        .player-avatar {
            max-width: 65%;
            margin: 0px auto;
            position: relative;
            @media (max-width: 768px) {
                max-width: 45%;
            }
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
                height: 80%;
                display: block;
                background-size: cover;
                background-position: top center;
                background-repeat: no-repeat;
                border-radius: 50%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(../img/player-avatar.png);
                background-size: 100%;
                background-position: top center;
                background-repeat: no-repeat;
                z-index: 2;
            }
            &:after {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }
    }
}
@font-face {
    font-family: 'Hi Jack';
    src: url('../fonts/hi-jack.woff2') format('woff2'),
         url('../fonts/hi-jack.woff') format('woff'),
         url('../fonts/hi-jack.ttf')  format('truetype');
  }
  
  
  @font-face {
    font-family: 'Front Page Neue';
    src: url('../fonts/Front Page Neue.woff2') format('woff2'),
         url('../fonts/Front Page Neue.woff') format('woff'),
         url('../fonts/Front Page Neue.ttf')  format('truetype');
  }
.search-panel{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    form {
        position: relative;
        flex: 0 0 100%;
        max-width: 360px;
        background: #001253;
        border: 3px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 6px;
        overflow: hidden;
        padding-right: 40px;
        input[type="text"] {
            height: 40px;
            border: none;
            background: none;
            outline: none;
            padding: 0 0 0 12px;
            font-family: 'Hi Jack';
            font-weight: 400;
            font-size: rem(24px);
            line-height: rem(28px);
            width: 100%;
            color: #FFFFFF;
            &::placeholder {
                color: rgba(#fff, 0.6);
            }
        }
        button[type="submit"] {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: none;
            outline: none;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
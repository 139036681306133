.container {
    max-width: 1120px;
    width: 100%;
    flex: 0 0 100%;
    padding: 0 15px;
    margin: 0px auto;
}

.col-6 {
    flex: 0 0 50%;
    @media (max-width: 768px) {
        flex: 0 0 100%;
    }
}
.d-flex {
    display: flex;
    flex-wrap: wrap;
}
.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-space-between {
    justify-content: space-between;
}
.position-relative {
    position: relative;
}
.position-fixed {
    position: fixed;
}
.position-absolute {
    position: absolute;
}
.z-index {
    z-index: 2;
}
.text-center {
    text-align: center;
}
.hide-mobile {
    @media (max-width: 768px) {
        display: none !important;
    }
}
.bg-inline {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.game-section-title {
    font-family: 'Hi Jack';
    font-style: normal;
    font-weight: 400;
    font-size: rem(48px);
    line-height: rem(55px);
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.game-section {
    padding: 70px 0;
    overflow: hidden;
}

.vh-100 {
    min-height: 100vh;
    @media (max-width: 768px) {
        min-height: 0;
    }
}

.img-title {
    text-align: center;
    p {
        margin: 20px 0;
        font-family: 'Front Page Neue';
        font-style: normal;
        font-weight: 400;
        font-size: rem(32px);
        line-height: rem(37px);
        color: #FFFFFF;;
        @media (max-width: 768px) {
            font-size: rem(16px);
            line-height: rem(18px);
        }
    }
}
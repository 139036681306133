:root {
    --radio: 0.7258064516129032;
}


html {
    font-size: 16px;
}
body {
    font-family: 'Hi Jack', sans-serif;
    margin: 0;
    padding: 0;
    background: #212766;
    overflow-x: hidden;
    &.not-home {
        background-image: url(../img/bg-1.png);
        background-size: cover;
        background-position: top center;
        background-attachment: fixed;
         background-repeat: no-repeat;
    }
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
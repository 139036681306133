.betting-panel {
    padding-bottom: 30px;
    padding-top: 100px;
    @media (max-width: 1199px) {
        padding-top: 40px;
    }
    .container {
        max-width: 1700px;
    }
    .betting-body {
        margin-top: 32px;
        .player-cards {
            flex-wrap: wrap;
            margin: 0 -16px;
            > * {
                @media (min-width: 1200px) {
                    flex: 0 0 calc(25% - 32px);
                    margin: 0 16px;
                }
                @media (max-width: 1199px) {
                    flex: 0 0 calc(33.333333% - 32px);
                    margin: 0 16px;
                }
                @media (max-width: 768px) {
                    flex: 0 0 calc(50% - 16px);
                    margin: 0 8px;
                }
                @media (max-width: 500px) {
                    flex: 0 0 calc(100% - 16px);
                    margin: 0 8px;
                }
            }
            @media (max-width: 768px) {
                margin: 0 -8px;
            }
        }
        .betting-cards {
            padding: 0px 30px 15px;
            background: #001343;
            border: 2px solid #989898;
            box-sizing: border-box;
            border-radius: 20px;
            margin: 55px 0 55px;
          
            &:last-child {
                margin-bottom: 0;
            }
            .betting-cards-heading {
                display: block;
                width: 515px;
                max-width: 100%;
                background-image: url(../img/betting-title.png);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                text-align: center;
                margin: 0px auto 30px;
                transform: translate(0, -50%);
                > * {
                    margin: 0;
                    padding: 1% 0;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 37px;
                    /* identical to box height */

                    letter-spacing: 0.1em;

                    background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    @media (max-width: 768px) {
                        font-size: 20px;
                        line-height: 23px;
                    }
                    
                }
            }
            .swiper {
                margin-right: -30px;
                margin-left: -30px;
                padding: 0 30px;
                @media (max-width: 768px) {
                    height: 840px !important;
                    overflow-y: auto;
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                @media (max-width: 500px) {
                    height: 820px !important;
                }
                .swiper-wrapper {
                    @media (max-width: 768px) {
                        transition-duration: 0ms !important; transform: translate3d(0px, 0px, 0px) !important;
                    }
                }
                .swiper-slide {
                    @media (max-width: 768px) {
                        height: 315px !important;
                    }
                    @media (max-width: 550px) {
                        height: 265px !important;
                    }
                }
                .betting-card {
                    
                }
                .swiper-button-prev,
                .swiper-button-next {
                    height: calc(100%);
                    width: 80px;
                    top: 0;
                    margin-top: 0;
                    img {
                        max-width: 42px;
                    }
                    &::after {
                        display: none;
                    }
                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                    @media (max-width: 768px) {
                        
                        display: none;
                    }
                }
                .swiper-button-prev {
                    left: 0;
                }
                .swiper-button-next {
                    
                    
                    right: 0
                }
            }
            .swiper-wrapper {
                padding-bottom: 30px;
            }
            .swiper-slide  {
                overflow: inherit;
            }
        }
    }
}
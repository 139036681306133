.btn-base {
    font-weight: 400;
    font-size: rem(28px);
    line-height: rem(32px);
    color: #000B73;
    mix-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 43px;
    padding-top: 2px;
    background-image: url(../img/btn/btn-base.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.btn-medium {
    font-family: 'Hi Jack';
    font-style: normal;
    font-weight: 400;
    font-size: rem(48px);
    line-height: 1;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    background-image: url(../img/btn/btn.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 272px;
    height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        width: 166px;
        height: 53px;
        font-size: rem(32px);
        padding-top: 5px;
    }
}
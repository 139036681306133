.main-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    @media (max-width: 768px) {
        img {
            max-width: 252px;
        }
    }
    .group-btn {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: rem(122px);
        gap:0 rem(50px)
    }
}
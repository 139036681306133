.baoe-second-section {
    position: relative;
    background-image: url(../img/bg-1.png), url(../img/isolation.png);
    background-size: 100%, 100%;
    background-position: top center, 80vh center;
    background-repeat: no-repeat, no-repeat;
    padding-bottom: 120px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: #394281;
        height: rem(42px);
        width: 100%;
        z-index: -2;
        @media (max-width: 768px) {
            height: 22px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #394281;
        height: rem(42px);
        width: 100%;
        z-index: -2;
        @media (max-width: 768px) {
            height: 22px;
        }
    }
    .panel-left {
        background-image: url(../img/panel-left.svg);
        top: 0;
        left: 0;
        &:before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }
    .panel-right {
        background-image: url(../img/panel-right.svg);
        top: 0;
        right: 0;
        &:before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }
    .panel-middle {
        max-width: 403px;
        width: 20%;
        background-image: url(../img/panel-middle.svg);
        top: 0;
        left: 50%;
        background-position: top center;
        transform: translate(-50%, 0);
        &:before {
            padding-top: 4%;
            content: '';
            display: block;
        }
    }
    .panel-bottom-left {
        background-image: url(../img/panel-bottom-left.png);
        bottom: 0;
        left: 0;
        &:before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }
    .panel-bottom-right {
        background-image: url(../img/panel-bottom-right.png);
        bottom: 0;
        right: 0;
        &:before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }
    .panel-bottom-middle {
        max-width: 403px;
        width: 20%;
        background-image: url(../img/panel-bottom-middle.png);
        bottom: 0;
        left: 50%;
        background-position: top center;
        transform: translate(-50%, 0);
        &:before {
            padding-top: 4%;
            content: '';
            display: block;
        }
    }
    .panel-left,
    .panel-right,
    .panel-middle,
    .panel-bottom-left,
    .panel-bottom-right,
    .panel-bottom-middle {
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        width: 20%;
        max-width: 400px;
        z-index: -1;
    }
}

.game-story {
    @media(min-width: 501px) {
        background-image: url(../img/game-story.png); 
    }
    @media (max-width: 500px) {
        background-image: url(../img/game-story-mobile.png);
        &:before {
            content: '';
            display: block;
            padding-top: 142%;
        }
        > img {
            display: none;
        }
    }
    > img {
        width: 100%;
    }
    .game-story-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .game-section-title {
            height: 50px;
            padding-top: 8px;
        }
        .desc {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 10%;
            text-align: justify;
            font-size: rem(24px);
            line-height: rem(28px);
            font-family: 'Front Page Neue';
            color: #fff;
            @media (max-width: 768px) {
                font-size: rem(14px);
                line-height: rem(18px);
            }
            @media (max-width: 500px) {
                font-size: rem(10px);
                line-height: rem(14px);
            }
            * {
                margin: 0;
                font-weight: 400;
            }
        }
    }
}

.game-section{
    .bg-overlay {
        z-index: 1;
        img {
            display: none;
        }
        &:before {
            content: '';
            padding-top: 63%;
            display: block;
        }
    }
    .tabs {
        
        &.tab-inline {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 6% 0 6.6%;
            height: 38%;
            width: 100%;
            z-index: 2;
            .tabs-head {
                display: flex;
                height: 18%;
                * {
                    flex: 0 0 23%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Hi Jack';
                    font-style: normal;
                    font-weight: 400;
                    font-size: rem(28px);
                    line-height: rem(32px);
                    letter-spacing: 0.05em;
                    order: 1;
                    padding-top: 8px;
                    @media (max-width: 768px) {
                        font-size: rem(14px);
                        line-height: rem(18px);
                    }
                    @media (max-width: 500px) {
                        font-size: rem(12px);
                        line-height: rem(16px);
                    }
                    @media (max-width: 450px) {
                        font-size: rem(10px);
                        line-height: rem(14px);
                        padding-top: 2px;
                    }
                    &.active {
                        background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        order: 0;
                    }
                    &:not(.active) {
                        background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        opacity: 0.6;
                    }
                }
            }
            .tabs-body {
                display: flex;
                flex-wrap: wrap;
                height: 58%;
                align-items: center;
                justify-content: center;
                .tab-item {
                    padding: 1% 5%;
                    flex: 0 0 100%;
                    > * {
                        margin: 0;
                        font-family: 'Front Page Neue';
                        font-style: normal;
                        font-weight: 400;
                        font-size: rem(20px);
                        line-height: rem(23px);
                        text-align: justify;
                        color: #FFFFFF;
                        @media (max-width: 768px) {
                            font-size: rem(14px);
                            line-height: rem(18px);
                        }
                        @media (max-width: 500px) {
                            font-size: rem(10px);
                            line-height: rem(14px);
                        }
                        @media (max-width: 800px) {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                        }
                    }
                }
            }
            &.metaverse-tabs {
                .tabs-head {
                    * {
                        flex: 0 0 34%;
                    }
                }
            }
        }
        &.fungible-tabs {
            margin-top: rem(32px);
            .tabs-head {
                max-width: 845px;
                margin: 0px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                > * {
                    text-align: center;
                    flex: 0 0 50%;
                    font-family: 'Hi Jack';
                    font-style: normal;
                    font-weight: 400;
                    font-size: rem(32px);
                    line-height: rem(37px);
                    letter-spacing: 0.05em;

                    color: #FFFFFF;

                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-right: solid rem(5px) #fff;
                    padding-top: rem(4px);
                    opacity: 0.3;
                    @media (max-width: 768px) {
                        font-size: rem(18px);
                        line-height: rem(21px);
                        border-right-width: 3px;
                    }
                    &.active {
                        opacity: 1;
                    }
                    &:last-child {
                        border-color: transparent;
                    }
                }
            }
        }
        
        
    }
    .js-gallery {
        position: absolute;
        top: 10%;
        left: 6.6%;
        height: 81%;
        width: calc(100% - 12.6%);
        z-index: 0;
        .swiper-slide  {
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
        }
        .js-gallery-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            &.show-in {
                opacity: 1;
                z-index: 1;
            }
            &.active {
                opacity: 1;
                z-index: 2;
            }
        }
    }
}
.gallery-thumbnail {
    margin-top: 8px;
    .swiper-slide {
        position: relative;
        &.swiper-slide-thumb-active {
            .mask {
                background-image: url(../img/board-gallery-active.png);
            }
        }
        .mask {
            background-image: url(../img/board-gallery.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            display: block;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                padding-top: 80.5%;
            }
        }
        .thumb {
            width: 78%;
            height: 66%;
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    .swiper-pagination {
        position: relative;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        margin-top: rem(24px);
        .swiper-pagination-bullet {
            background: rgba(20, 106, 154, 0.4);
            border-radius: 11px;
            margin: 0px 20px;
            width: 8%;
            height: auto;
            opacity: 1;
            position: relative;
            overflow: hidden;
            @media (max-width: 768px) {
                width: 40px;
                margin: 5px;
            }
            &.swiper-pagination-bullet-active {
                box-shadow: 0 0 3px #0FBCFC;
                &:before {
                    background-color: #6066FF;
                }
            }
            &:before {
                content: '';
                display: block;
                padding-top: 7%;
            }
        }
    }
}



.roadmap-list {
    @media (min-width: 500px) {
        .mobile {
            display: none;
        }
    }
    @media (max-width: 499px) {
        .desktop {
            display: none;
        }
    }
}
#site-header {
    position: fixed;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #212766;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #5300BC 6.02%, rgba(44, 6, 156, 0.551243) 61.07%, rgba(0, 12, 119, 0.06) 100%);
    }
    > div {
        position: relative;
        z-index: 2;
    }
    .site-logo {
        flex: 0 0 170px;
        @media (max-width: 1199px) {
            flex: 0 0 94px;
        }
    }
    .hammer-menu {
        @media (min-width: 1200px) {
            display: none;
        }
        position: absolute;
        top: 30px;
        right: 10px;
        transform: translate(0%, -50%);
        width: 34px;
        cursor: pointer;
        padding: 5px;
        .close {
            display: none;
        }
        &.active {
            .hammer {
                display: none;
            }
            .close {
                display: block;
            }
        }
    }
    .site-navigation {
        flex: 0 0 calc(100% - 170px);
        align-items: center;
        @media (min-width: 1200px) {
            display: flex !important;
        }
        
        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex: 0 0 calc(100% - 150px);
            .nav-item {
                .nav-link {
                    font-family: 'Hi Jack';
                    font-weight: 400;
                    font-size: rem(28px);
                    line-height: rem(32px);
                    letter-spacing: 0.1em;
                    color: #FFFFFF;
                    @media (max-width: 1400px) {
                        font-size: rem(18px);
                        line-height: rem(22px);
                    }
                }
            }
        }
        @media (max-width: 1199px) {
            display: none;
            flex: 0 0 100%;
            max-height: calc(100vh - 57.39px);
            overflow: auto;
            padding-top: 25px;
            &.active {
                display: block;
            }
            ul {
                display: block;
                text-align: center;
                .nav-item {
                    .nav-link {
                        font-size: rem(24px);
                        line-height: rem(28px);
                        padding: 25px 0;
                        display: block;
                    }
                }
            }
            .site-actions {
                padding: 25px 0;
                .btn {
                    margin: 0px auto;
                    font-size: rem(24px);
                }
            }
        }
    }
    .site-actions {
        flex: 0 0 150px;
        .btn {
            @media (max-width: 1400px) {
                font-size: rem(18px);
            }
        }
    }
}
.popup {
    &#bettingPopup{
        width: 780px;
        height: 330px;
        background-image: url(../img/betting-popup.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        margin: 0px auto;
        position: relative;
        .popup-heading {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            > * {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                /* identical to box height */

                letter-spacing: 0.1em;
                background-color: #22285C;
                background: linear-gradient(180deg, rgba(215, 97, 31, 0.8) 18.75%, rgba(242, 157, 109, 0.510638) 52.08%, rgba(249, 211, 191, 0) 68.23%), #FFFFFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                color: #22285C;

            }
        }
        .popup-body {
            padding: 125px 60px 0;
            form {
                .form-submit {
                    text-align: center;
                    margin-top: 25px;
                    button[type="submit"] {
                        background: none;
                        box-shadow: 0;
                        outline: none;
                        border: none;;
                    }
                }
                label {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 20px;
                    color: #FFFFFF;
                    mix-blend-mode: normal;
                    display: block;
                    margin-bottom: 10px;
                }
                .form-field {
                    background: #00305B;
                    border: 1px solid #989898;
                    box-sizing: border-box;
                    border-radius: 4px;
                    position: relative;
                    padding-right: 55px;
                    input[type="text"] {
                        background-color: none;
                        border: none;
                        height: 48px;
                        background: none;
                        padding: 0 40px 0 8px;
                        outline: none;
                        font-size: 24px;
                        line-height: 28px;
                        font-family: 'Hi Jack';
                        color: #FFFFFF;
                        width: 100%;

                        &::placeholder {
                           color: rgba(#fff, 0.2);
                            mix-blend-mode: normal;
                            opacity: 0.2;
                        }
                    }
                    .text {
                        position: absolute;
                        top: 50%;
                        right: 8px;
                        transform: translate(0, -50%);
                        font-size: 24px;
                        line-height: 28px;
                        /* identical to box height */


                        color: #FFFFFF;

                        mix-blend-mode: normal;
                    }
                }
            }
        }
        
    }
}
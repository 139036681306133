.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    > * {
        line-height: 0;
        margin: 0 6px;
    }
    .total {
        font-family: 'Front Page Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        /* identical to box height */
        color: #FFFFFF;
    }
    .number-of-page {
        input {
            background: #001343;
            border: 2px solid #989898;
            width: 100%;
            max-width: 50px;
            font-family: 'Front Page Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            /* identical to box height */
            padding: 0 5px;
            text-align: right;
            color: #FFFFFF;
            border-radius: 5px;
            height: 30px;
        }
    }
}
.betting-card {
    background: linear-gradient(90deg, #212B42 0%, #212C42 98.69%);
    border-radius: 14px;
    position: relative;
    .betting-card-head {
        display: flex;
        flex-wrap: wrap;
        padding: 17px 20px;
        background: linear-gradient(90deg, #4940C6 0%, #212C42 69.39%);
        border-radius: 14px 14px 10px 10px;
        * {
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0.1em;
            color: #FFFFFF;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 14px;
            }
        }
        .betting-match-earn {
            margin-left: auto;
        }
    }
    .betting-card-result-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 119px;
        max-width: 90%;
        line-height: 0;
        text-align: center;
    }
    .betting-card-body {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
        .betting-card-item {
            flex: 0 0 33.333333%;
            text-align: center;
            position: relative;
            .versus-infor {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                height: 100%;
                > * {
                    flex: 0 0 100%;
                }
                .versus-result {
                    margin-bottom: 30px;
                    p {
                        font-family: 'Hi Jack';
                        font-style: normal;
                        margin: 0;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 37px;
                        /* identical to box height */
                        text-align: center;
                        letter-spacing: 0.1em;
                        color: #FFFFFF;
                        @media (max-width: 768px) {
                            font-size: 20px;
                            line-height: 23px;
                        }
                    }
                    .win {
                        background: linear-gradient(180deg, #04E08C 0%, #008150 78.38%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                }
                p {
                    font-family: 'Front Page Neue';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    color: #FFFFFF;
                    margin: 0px;
                }
            }
        }
        .betting-card-button {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 110%);
            width: 119px;
            max-width: 90%;
            line-height: 0;
            a {
                display: inline-block;
            }
            img {
                cursor: pointer;
            }
        }
        .betting-card-infor {
            p {
                font-family: 'Front Page Neue';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                margin: 5px 0 0;
                color: #FFFFFF;
                b, strong {
                    font-weight: 400;
                    color: #5887FF;

                }
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
        .betting-card-avatar {
            max-width: 65%;
            margin: 0px auto;
            position: relative;
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
                height: 80%;
                display: block;
                background-size: cover;
                background-position: top center;
                background-repeat: no-repeat;
                border-radius: 50%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(../img/player-avatar.png);
                background-size: 100%;
                background-position: top center;
                background-repeat: no-repeat;
                z-index: 2;
            }
            &:after {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }
    }
}
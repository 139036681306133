.team-member-list,
.partner-list {
    display: flex;
    flex-wrap: wrap;
   
    .item {
        padding: 0 15px;
        margin-top: rem(80px);
        flex: 0 0 25%;
        @media (max-width: 768px) {
            margin-top: rem(40px);
            flex: 0 0 100%;
            padding: 0 8px;
        }
        img {
            margin: 0 0 rem(15px);
            transition: filter linear 250ms;
            cursor: pointer;
            @media (max-width: 768px) {
                max-width: calc(50% - 16px);
            }
            &:hover {
                filter: drop-shadow(0px 0px 7px #0FBCFC);
                -webkit-filter: drop-shadow(0px 0px 7px #0FBCFC);
            }
        }
        p, h3 {
            font-size: rem(24px);
            line-height: rem(28px);
            font-weight: 400;
            color: #FFFFFF;
            margin: 0;
        }
        h3 {
            @media (max-width: 768px) {
                font-size: rem(18px);
                line-height: rem(21px);
            }
        }
        p {
            font-family: 'Front Page Neue';
            font-weight: 400;
            @media (max-width: 768px) {
                font-size: rem(16px);
                line-height: rem(18px);
            }
        }
    }
    &.mobile-2-cols {
        .item {
            @media (max-width: 768px) {
                flex: 0 0 50%;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.partner-list {
    .item {
        margin-top: rem(60px);
        flex: 0 0 33.33333333%;
        @media (max-width: 768px) {
            margin-top: rem(24px);
            flex: 0 0 100%;
        }
        img {
            margin: 0;
        }
    }
    &.mobile-2-cols {
        .item {
            @media (max-width: 768px) {
                flex: 0 0 50%;
                max-width: 100%;
            }
        }
    }
}